import { theme } from "../theme";
import "./Sections.css";

export function ContactContent() {
  return (
    <div className="Contact">
      <h1 id="chat">Let's chat!</h1>
      <p>
        I am building{" "}
        <a href="https://sensive.xyz/" target="_blank" rel="noreferrer">
          my own product
        </a>
        . Let me help you to build and scale yours. I value building strong
        relationships and sharing of knowledge with teams who think like we do.
        Long-term commitment to building a service that improves our customers
        lives.
      </p>
      <p>Let's chat if you need guidance in any of these areas;</p>
      <ul>
        <li>Serverless Platforms</li>
        <li>Product Architecture</li>
        <li>Organisational optimisation in the cloud</li>
        <li>Startup culture</li>
      </ul>
    </div>
  );
}

export function ContactMedia() {
  return (
    <div
      className="Contact"
      style={{
        flex: 1,
        ...theme.centered,
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <a
        className="ContactLink"
        href="https://au.linkedin.com/in/brian-patrick-foody"
        rel="noreferrer"
        target="_blank"
      >
        LinkedIn
      </a>
      <p>♾️</p>
      <a
        className="ContactLink"
        href="https://twitter.com/foodybrian?lang=en"
        rel="noreferrer"
        target="_blank"
      >
        Twitter
      </a>
      <p>♾️</p>
      <a
        className="ContactLink"
        href="tel:+61488057732"
        rel="noreferrer"
        target="_blank"
      >
        Phone
      </a>
      <p>♾️</p>
      <a
        className="ContactLink"
        href="mailto:brianfoody@gmail.com"
        rel="noreferrer"
        target="_blank"
      >
        Email
      </a>
    </div>
  );
}
