import "./AboveTheFold.css";
import banner from "./assets/banner.png";
// import { useEffect, useState } from "react";
// import times from "lodash.times";
// import { isOnMobile } from "./helpers";

type AboveTheFoldProps = {};

// const countTarget = isOnMobile() ? 15 : 10;

export default function AboveTheFold(_: AboveTheFoldProps) {
  return (
    <div className="AboveTheFold">
      <AboveTheFoldContent />

      <div className="AboveTheFoldImage">
        <img
          src={banner}
          alt="Brian Foody presenting at Latency Cloud Conference, November 2020"
        />
      </div>
    </div>
  );
}

function AboveTheFoldContent() {
  // const [count, setCount] = useState(0);
  // const [loaded, setLoaded] = useState(false);
  // const [animationFinished, setAnimationFinished] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoaded(true);
  //   }, 1000);
  //   setTimeout(() => {
  //     setAnimationFinished(true);
  //   }, 75 * countTarget + 1000 + 1250);
  // }, []);

  // useEffect(() => {
  //   if (!loaded) return;

  //   const id = setInterval(() => {
  //     if (count) return;
  //     setCount((prev) => (prev >= countTarget ? countTarget : prev + 1));
  //   }, 75);
  //   return () => {
  //     clearInterval(id);
  //   };
  // }, [loaded, count]);

  return (
    <div className="AboveTheFoldContent">
      <div className="MainTextContainer">
        <h1 className="MainText">
          I build <Spacer />
        </h1>
        <h1 className="MainText MainTextEm">
          delightful <Spacer />
        </h1>
        <h1 className="MainText">
          products that <Spacer />
        </h1>
        <h1 className="MainText">
          scale. <Spacer />
        </h1>

        {/* <Intro count={count} animationFinished={animationFinished} /> */}
      </div>
    </div>
  );
}

// type IntroProps = {
//   count: number;
//   animationFinished: boolean;
// };

// function Intro({ count, animationFinished }: IntroProps) {
//   return (
//     <div style={{ position: "relative" }}>
//       <h1 className="MainText">
//         {times(countTarget - 1).map((_, i) => (
//           <Igniter lit={i < count} key={`Igniter${i}`} />
//         ))}

//         <h1
//           className="InnerMainEmoji"
//           style={{ opacity: count === countTarget ? 1 : 0 }}
//         >
//           {count === countTarget ? "🔥" : "⚫"}
//         </h1>

//         <Spacer />
//         <EmojiRocket launch={count === countTarget} />

//         <div className="ScaleWrapper">
//           <h1
//             className={`MainText Scale ${animationFinished ? "AnimateIn" : ""}`}
//           >
//             scale.
//             {isOnMobile() && <Spacer large={true} />}
//           </h1>
//         </div>
//       </h1>
//     </div>
//   );
// }

// type IgniterProps = { lit: boolean };
// function Igniter({ lit }: IgniterProps) {
//   return <h1 className="InnerMainEmoji">{lit ? "🔥" : "⚫"}</h1>;
// }

type SpacerProps = { large?: boolean };
function Spacer({ large }: SpacerProps) {
  return (
    <span className="MainText InnerMainText">{large ? "_________" : "_"}</span>
  );
}

// type EmojiRocketProps = {
//   launch: boolean;
// };
// function EmojiRocket({ launch }: EmojiRocketProps) {
//   return (
//     <div className={`EmojiRocketOuter ${launch ? "Launch" : ""}`}>
//       <h1 className="EmojiRocket"> 🚀</h1>
//     </div>
//   );
// }
