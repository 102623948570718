import "./Sections.css";
import { useLayoutEffect, useRef, useState } from "react";
import { isOnMobile } from "../helpers";

type MediaProps = {
  src: string;
  altText?: string;
  link?: string;
  aspectWidth: number;
  aspectHeight: number;
};
type ImgOptions = {
  width: number;
  height: number;
  render: boolean;
};
export function Image({
  altText,
  aspectWidth,
  aspectHeight,
  link,
  src,
}: MediaProps) {
  const [options, setOptions] = useState<ImgOptions>({
    height: 0,
    width: 0,
    render: false,
  });

  const targetRef = useRef<any | undefined>();

  const aspectRatio = aspectHeight / aspectWidth;

  useLayoutEffect(() => {
    if (targetRef.current && !options.width) {
      const targetWidth = isOnMobile()
        ? targetRef.current.offsetWidth
        : targetRef.current.offsetWidth * 0.7;
      setOptions({
        ...options,
        render: targetWidth > 0,
        width: targetWidth,
        height: targetWidth * aspectRatio,
      });
    }
  }, [options, aspectRatio]);

  const img = () => (
    <img
      src={src}
      style={{ width: options.width, height: options.height }}
      alt={altText || "Brian Foody"}
    />
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={targetRef}
    >
      {options.render &&
        (link ? (
          <a href={link} target="_blank" rel="noreferrer">
            {img()}
          </a>
        ) : (
          img()
        ))}
    </div>
  );
}
