import { theme } from "../theme";
import "./Sections.css";
import Story from "../assets/laptop.jpeg";

export function CraftContent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <h1>Technology that scales 🚀</h1>
      <p>
        I have worked at the forefront of Serverless and Cloud Native technology
        since 2015 and share my passion through{" "}
        <a
          href="https://medium.com/lambda-lego"
          rel="noreferrer"
          target="_blank"
        >
          my writing
        </a>
        .
      </p>

      <p>
        Through building products across a broad array of industries I have
        gathered a set of principles and tools that allow me to cross the entire
        AWS ecosystem to rapidly deliver value.
      </p>

      <p>
        As a certified AWS Solutions Architect Professional and Community
        Builder I also leverage my strong network to ensure all my advice is at
        the forefront of cloud.
      </p>
    </div>
  );
}

export function CraftMedia() {
  return (
    <div style={{ flex: 1, ...theme.centered }}>
      <img src={Story} style={{ width: "100%" }} alt="Alt text" />
    </div>
  );
}
