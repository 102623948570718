import AboveTheFold from "./AboveTheFold";
import "./App.css";
import "./Utils.css";

import { Section, SectionContent } from "./Utils";
import { MeContent } from "./sections/Me";
import { PhilosophyContent } from "./sections/Philosophy";
import { CraftContent } from "./sections/Craft";
import {
  DeliveryContent,
  DeliveryMediaWeb,
  DeliveryMediaMobile,
} from "./sections/Delivery";
import { Media } from "./sections/Media";
import { isOnMobile } from "./helpers";
import { ContactContent, ContactMedia } from "./sections/Contact";
import { Image } from "./sections/Image";
import CloudNative from "./assets/cloudnative.png";
import { theme } from "./theme";

function App() {
  return (
    <div className="App">
      <Section type="Banner">
        <AboveTheFold />
      </Section>

      <Section type="Child">
        <SectionContent direction="Right">
          <MeContent />
          <Media videoId="uQBQx9cY1a4" />
        </SectionContent>
      </Section>

      <Section type="Child">
        <SectionContent direction="Left">
          <PhilosophyContent />
          <Media videoId="md_FfGqJs2Q" />
        </SectionContent>
      </Section>

      <Section type="Child">
        <SectionContent direction="Right">
          <CraftContent />
          <Image
            src={CloudNative}
            aspectHeight={1838}
            aspectWidth={1515}
            altText="Lambda Lego blog by Brian Foody"
            link="https://medium.com/lambda-lego/day-one-cloud-native-organisations-250b4e181a8d"
          />
        </SectionContent>
      </Section>

      <Section type="Child">
        <SectionContent direction="Left">
          <DeliveryContent />
          {isOnMobile() ? <DeliveryMediaMobile /> : <DeliveryMediaWeb />}
        </SectionContent>
      </Section>

      <Section type="Child">
        <SectionContent direction="Right">
          <ContactContent />
          <ContactMedia />
        </SectionContent>
      </Section>

      <div
        style={{
          paddingTop: isOnMobile() ? 80 : 0,
          width: "100%",
          ...theme.centered,
          flexDirection: "column",
        }}
      >
        <h2>Designed, built and run by me,</h2>
        <h2>All on AWS Serverless for free 🤓.</h2>
      </div>
    </div>
  );
}

export default App;
