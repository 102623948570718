import { theme } from "../theme";
import "./Sections.css";
import Story from "../assets/laptop.jpeg";

export function PhilosophyContent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <h1>My Philosophy.</h1>
      <p>Slow is smooth and smooth is fast.</p>
      <p>
        Delivering great software requires discipline and immersion in a problem
        domain. A new feature should enhance others. A new optimisation should
        optimise the whole.
      </p>
      <p>
        I think long term, ensure complete understanding through BDD methods and
        consider the overall health of your technical ecosystem as I work.
      </p>
    </div>
  );
}

export function PhilosophyMedia() {
  return (
    <div style={{ flex: 1, ...theme.centered }}>
      <img src={Story} style={{ width: "100%" }} alt="Alt text" />
    </div>
  );
}
