// import { theme } from "../theme";
import "./Sections.css";
import YouTube, { Options } from "react-youtube";
import { useLayoutEffect, useRef, useState } from "react";

type MediaProps = {
  videoId: string;
};
export function Media({ videoId }: MediaProps) {
  const [options, setOptions] = useState<Options>({
    height: "0",
    width: "0",
    playerVars: {
      autoplay: 0,
    },
  });

  const targetRef = useRef<any | undefined>();

  useLayoutEffect(() => {
    if (targetRef.current && options.width === "0") {
      setOptions({
        ...options,
        width: "100%",
        height: (targetRef.current.offsetWidth as number) * 0.7 + "",
      });
    }
  }, [options]);

  return (
    <div style={{ width: "100%" }} ref={targetRef}>
      <YouTube videoId={videoId} opts={options} />
    </div>
  );
}
