import { isOnMobile } from "../helpers";
import "./Sections.css";

export function MeContent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <h1>Everything starts with a story.</h1>
      <p>Every great company, product and service is bound by a story.</p>

      <p>
        Technology should amplify this story, yet all too often it's lost in the
        translation to digital.
      </p>

      <p>
        My passion lies in immersing myself in the tale and blending it with the
        simplest technology possible so that a global audience can hear and
        delight in it.
      </p>

      <p>
        This talk tells my story so far <em>{!isOnMobile() ? "➡️" : "⬇️"}</em>
      </p>
    </div>
  );
}
