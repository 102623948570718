import "./Utils.css";

type SectionProps = {
  type: "Banner" | "Child";
  children: JSX.Element | JSX.Element[];
};
export function Section(props: SectionProps) {
  return <div className={`Section ${props.type}`}>{props.children}</div>;
}

type SectionRightProps = {
  direction: "Left" | "Right";
  children?: JSX.Element[];
};
export function SectionContent({ children, direction }: SectionRightProps) {
  if (!children) return <div className={`SectionContent ${direction}`} />;

  return (
    <div className={`SectionContent ${direction}`}>
      <div className="SectionContentChild">
        <div className="SectionContentWrapper">{children[0]}</div>
      </div>

      <div className="SectionContentChild">
        <div className="SectionContentWrapper">{children[1]}</div>
      </div>
    </div>
  );
}

type FlexProps = {
  type: "row" | "column";
  children: JSX.Element | JSX.Element[];
  style?: any;
};

export function Flex(props: FlexProps) {
  return (
    <div
      style={{
        ...(props.style || {}),
        display: "flex",
        flex: 1,
        flexDirection: props.type,
      }}
    >
      {props.children}
    </div>
  );
}
