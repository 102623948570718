import { theme } from "../theme";
import "./Sections.css";
import Sensive from "../assets/sensive.png";
import Bamboo from "../assets/bamboo.png";
import Marinelink from "../assets/marinelink.png";
import Synergy from "../assets/synergy.png";
import Wilson from "../assets/wilson.png";
import Intelicare from "../assets/Intelicare.png";
import { useLayoutEffect, useRef, useState } from "react";
import { isOnMobile } from "../helpers";

export function DeliveryContent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <h1>A proven history of award winning products.</h1>
      <p>I have built award-winning products and scaled startups to exit.</p>
      <p>
        From IOT in the middle of the sea to building investment banks from the
        ground up, I love working alongside great prople to solve problems that
        matter.
      </p>
      <p>
        Here are the last six projects I've worked on, as either Lead Engineer,
        CTO or Co-Founder.
      </p>
      <p>
        (
        <em>
          pssst: I link to live products, all my work is still running in
          production {!isOnMobile() ? "⬅️ " : "⬇️"}
        </em>
        )
      </p>
    </div>
  );
}

export function DeliveryMediaWeb() {
  const [size, setSize] = useState<number>(0);

  const targetRef = useRef<any | undefined>();

  useLayoutEffect(() => {
    if (targetRef.current && size === 0) {
      setSize(targetRef.current.offsetWidth * 0.3333333);
    }
  }, [size]);

  return (
    <div
      className="Delivery"
      style={{
        flex: 1,
        ...theme.centered,
        flexDirection: "column",
        overflow: "hidden",
      }}
      ref={targetRef}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          top: "4px",
          position: "relative",
        }}
        ref={targetRef}
      >
        <a
          href="https://apps.apple.com/au/app/bamboo-effortless-investment/id1386681221"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Bamboo}
            alt="Bamboo Effortless Investment"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://marinelink.austal.com/marinelink-smart"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Marinelink}
            alt="MARINELINK-Smart"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://apps.apple.com/us/app/sensive-mood-tracking/id1513799237"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Sensive}
            alt="Sensive Mood Tracker"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
      </div>

      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <a
          href="https://apps.apple.com/au/app/wilson-parking/id1368305246"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Wilson}
            alt="Wilson Parking"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://apps.apple.com/au/app/intelicare/id1181728420"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Intelicare}
            alt="Intelicare"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://selfserve.synergy.net.au/my-account.html"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Synergy}
            alt="Synergy My Account"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
      </div>
    </div>
  );
}

export function DeliveryMediaMobile() {
  const [size, setSize] = useState<number>(0);

  const targetRef = useRef<any | undefined>();

  useLayoutEffect(() => {
    if (targetRef.current && size === 0) {
      setSize(targetRef.current.offsetWidth * 0.5);
    }
  }, [size]);

  return (
    <div
      className="Delivery"
      style={{
        flex: 1,
        ...theme.centered,
        flexDirection: "column",
        overflow: "hidden",
      }}
      ref={targetRef}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          top: "4px",
          position: "relative",
        }}
        ref={targetRef}
      >
        <a
          href="https://apps.apple.com/us/app/sensive-mood-tracking/id1513799237"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Sensive}
            alt="Sensive Mood Tracker"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://apps.apple.com/au/app/bamboo-effortless-investment/id1386681221"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Bamboo}
            alt="Bamboo Effortless Investment"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
      </div>

      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <a
          href="https://marinelink.austal.com/marinelink-smart"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Marinelink}
            alt="MARINELINK-Smart"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>

        <a
          href="https://apps.apple.com/au/app/wilson-parking/id1368305246"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Wilson}
            alt="Wilson Parking"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <a
          href="https://apps.apple.com/au/app/intelicare/id1181728420"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Intelicare}
            alt="Intelicare"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
        <a
          href="https://selfserve.synergy.net.au/my-account.html"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={Synergy}
            alt="Synergy My Account"
            style={{
              flex: 1,
              width: size,
              height: size,
            }}
          />
        </a>
      </div>
    </div>
  );
}
